<script lang="ts" setup>
import Button4 from '@/components/Button/Button4.vue'
import type { IAdvert } from '~/api/advert';
const props = defineProps({
  type: {
    type: String,
    default: 'image' // text, image
  },
  data: {
    type: Object as PropType<IAdvert>,
    default: () => { }
  },
})

const isShow = useCookie('open_dialog_cover', {
  maxAge: 1000 * 60
})
if (isShow.value === 1) {
  isShow.value = 0
}

function close(open: number) {
  isShow.value = open
}
</script>

<template>
  <section class="dialog-cover" v-show="!isShow">
    <div class="dialog-cover__mask" @click="close(1)"></div>
    <div class="dialog-cover__inner" :class="`type-${props.type}`">
      <div class="dialog-cover__content">
        <div class="dialog-cover__image" v-if="data?.image?.link">
          <Nimg :src="data?.image?.link" :alt="data?.image?.alt" :title="data?.title" />
        </div>
        <div class="dialog-cover__title" v-html="data.title"></div>
        <div class="dialog-cover__text" v-html="data.text"></div>
      </div>
      <div class="dialog-cover__bottom">
        <Button4 linkTitle="知道了" @click="close(1)" />
        <Button4 icon="icon-close" linkTitle="不再顯示" class="dialog-cover__bottom__cancel" @click="close(2)" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.dialog-cover {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &__mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black-1, 0.9);
    cursor: pointer;
  }

  &__inner {
    position: relative;
    z-index: 1;
    background-color: $color-white-2;

    img {
      display: block;
      width: 100%;
      border-radius: toRem(12);
    }

    &.type-text {
      width: 100%;
      max-width: toRem(560);
      padding: toRem(56);
    }

    &.type-image {
      width: 100%;
      max-width: toRem(830);
      padding: toRem(12);
      max-height: 90vh;
      overflow-y: auto;

      .dialog-cover {
        &__content {
          margin-bottom: toRem(12);
        }
      }
    }
  }

  &__content {
    text-align: center;
    margin-bottom: toRem(56);
  }

  &__title {
    margin-bottom: toRem(12);
    font-size: toRem(20);
    font-weight: 500;
    line-height: 1.4;
    color: $color-black-1;
  }

  &__text {
    font-size: toRem(16);
    color: $color-gray-1;
    line-height: 1.45;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__cancel {
      background: none;

      :deep(.button__title) {
        font-weight: 400;
        font-size: toRem(14);
        color: $color-gray-1;
      }

      :deep(.button__icon) {
        color: $color-gray-1;
      }
    }
  }
}
</style>
