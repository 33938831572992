<script lang="ts" setup>
import { useGlobalStore, useUserStore } from '@/stores'
const globalStore = useGlobalStore()
const userStore = useUserStore()
interface List {
  title: string
  link?: any
}

const lists: Ref<List[]> = ref([
  {
    title: userStore.token === null ? '會員註冊/登入' : '會員中心',
    link: userStore.token === null ? import.meta.env.VITE_SSO_URL.replace("{:redirect_url}", import.meta.env.VITE_DOMAIN).replace("{:banner_id}", 1) : '/member'
  },
  {
    title: '維修/贈品進度查詢',
    link: '/member/service'
  }
])
</script>

<template>
  <div class="member" :class="{ dark: globalStore.isDark }">
    <div class="member__item">
      <div class="member__icon">
        <svg-icon name="icon-member" />
      </div>
      <div class="member__child">
        <div class="member__child__inner">
          <nuxt-link v-for="(child, childIndex) in lists" :key="childIndex" :to="child.link" :title="child.title"
            class="member__child__title">
            {{ child.title }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.member {
  &.dark {
    .member {
      &__icon {
        color: $color-white-1;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(40);
    height: toRem(30);

    svg {
      width: toRem(24);
      height: toRem(24);
    }
  }

  &__item {
    position: relative;
    cursor: pointer;

    &:hover {
      .member {
        &__child {
          opacity: 1;
          pointer-events: auto;
          transform: translateX(-50%) scale(1);
        }
      }
    }
  }

  &__child {
    position: absolute;
    left: 20%;
    transform: translateX(-50%) scale(1.2);
    top: 100%;
    width: toRem(185);
    padding-top: toRem(10);
    opacity: 0;
    pointer-events: none;
    transition: all 0.6s ease-in-out;

    &__inner {
      background-color: $color-gray-3;
      padding-top: toRem(10);
      padding-bottom: toRem(10);
    }

    &__title {
      display: block;
      padding-top: toRem(5);
      padding-bottom: toRem(5);
      font-size: toRem(16);
      text-align: center;
    }
  }
}
</style>
