<script lang="ts" setup>
const props = defineProps({
  linkTitle: String,
  icon: {
    type: String,
    default: 'icon-check'
  }
})
</script>

<template>
  <button class="button">
    <div class="button__icon">
      <svg-icon :name="props.icon" />
    </div>
    <div class="button__title" v-if="props.linkTitle">{{ props.linkTitle }}</div>
  </button>
</template>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background: none;
  padding: toRem(12) toRem(24);
  background-color: $color-brand-3;
  border-radius: toRem(50);
  &__icon {
    width: toRem(20);
    height: toRem(20);
    margin-right: toRem(6);
  }
  &__title {
    font-size: toRem(16);
    color: $color-white-1;
    font-weight: 700;
    line-height: 1.6;
  }
}
</style>
