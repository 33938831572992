<script lang="ts" setup>
import { useGlobalStore } from '@/stores'
const globalStore = useGlobalStore()
</script>

<template>
  <div class="search" :class="{ dark: globalStore.isDark }">
    <nuxt-link to="/search" class="search__icon">
      <svg-icon name="icon-search" />
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
.search {
  cursor: pointer;

  &.dark {
    .search {
      &__icon {
        color: $color-white-1;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(40);
    height: toRem(30);

    svg {
      width: toRem(24);
      height: toRem(24);
    }
  }
}
</style>
