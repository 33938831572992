<script lang="ts" setup>
import { useGlobalStore } from '@/stores';
import { storeToRefs } from 'pinia';
import HeaderMember from '@/components/Header/HeaderMember.vue';
import HeaderMenu from '@/components/Header/HeaderMenu.vue';
import HeaderSearch from '@/components/Header/HeaderSearch.vue';

const globalStore = useGlobalStore();
const { WEB_TITLE, isMobileMenuOpen, isNotFixed } = storeToRefs(globalStore);

const refHeader = ref(); // header ref

// const emit = defineEmits(['update:fixed'])

const isFixed: Ref<boolean> = ref(false);

const setFixed = (data: any) => {
  isFixed.value = data;
};

defineExpose({ setFixed });
</script>

<template>
  <header
    class="header"
    :class="{
      isFixed: isFixed,
      isNotFixed: isNotFixed,
      dark: globalStore.isDark,
      white: globalStore.isWhite,
      white1: globalStore.isWhite1,
      headerTransparent: globalStore.isHeaderTransparent
    }"
    ref="refHeader"
  >
    <div class="wrap">
      <figure class="header__logo">
        <nuxt-link to="/">
          <Nimg src="./images/logo.svg" :alt="WEB_TITLE" class="light" format=""></Nimg>
          <Nimg src="./images/logo_white.svg" :alt="WEB_TITLE" class="dark" format=""></Nimg>
        </nuxt-link>
      </figure>
      <div class="header__menu">
        <HeaderMenu />
      </div>
      <div class="header__right">
        <div class="header__right__item">
          <ClientOnly>
            <HeaderMember />
          </ClientOnly>
        </div>
        <div class="header__right__item">
          <HeaderSearch />
        </div>
      </div>
      <div class="header__mobile">
        <button class="mobile-menu-button" :class="{ dark: globalStore.isDark }" @click="isMobileMenuOpen = true" title="選單按鈕">
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 100;
  width: 100%;
  background-color: $color-white-2;

  &.isFixed {
    position: fixed;
    left: 0;
    top: 0;
  }

  &.isNotFixed {
    position: absolute;
  }

  .wrap {
    position: relative;
    display: flex;
    align-items: center;
    height: toRem(88);
  }

  &__menu {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include max-media(990) {
      display: none;
    }
  }

  &__right {
    display: flex;
    margin-left: auto;

    @include max-media(990) {
      display: none;
    }

    &__item {
      margin-left: toRem(10);
    }
  }

  &__mobile {
    display: flex;
    align-items: center;
    margin-left: auto;

    @include min-media(991) {
      display: none;
    }
  }

  &__logo {
    width: 100%;
    max-width: 160px;

    a {
      display: block;
    }

    img {
      display: block;
      width: 100%;
    }

    .dark {
      display: none;
    }
  }

  &.white1 {
    background-color: $color-white-1;
  }

  &.headerTransparent {
    background-color: transparent;
  }

  &.white {
    background-color: transparent;
    color: $color-white-1;

    :deep(a) {
      color: $color-white-1;
    }

    .header {
      &__logo {
        .dark {
          display: block;
        }

        .light {
          display: none;
        }
      }
    }
  }

  &.dark {
    background-color: $color-black-1;

    .header {
      &__logo {
        .light {
          display: none;
        }

        .dark {
          display: block;
        }
      }
    }
  }
}

.mobile-menu-button {
  position: relative;
  width: toRem(28);
  height: toRem(28);
  border: none;
  background: none;
  cursor: pointer;

  &.dark {
    div {
      background-color: $color-white-1;
    }
  }

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: toRem(23);
    height: toRem(2);
    background-color: $color-black-1;

    &:nth-child(1) {
      margin-top: toRem(-6);
    }

    &:nth-child(3) {
      margin-top: toRem(6);
    }
  }
}
</style>
